<template>
  <div class="content" style="background: #f6f7fb;">
    <template v-if="!detailVisible">
      <div class='card' style='display: flex;'>
        <div class="left" style="flex-shrink: 0; display: flex;">
          <!-- <el-button type='primary' @click='dialogVisible = true' icon='el-icon-plus'>新增退款</el-button> -->
          <!-- <el-button type='primary' @click='pickUpVisible = true'>提单</el-button> -->
          <!-- <el-button
            type='primary'
            plain
            @click='handleExport'
            icon='el-icon-upload2'
            :loading="loading3"
            style="margin-right: 10px; height: 40px;"
          >
            导出
          </el-button>
          <LocalPrint :tableType="2" :show="true" :loading="printLoading" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" @onPrint="handlePrint" /> -->
          <!-- <el-button
            type='primary'
            plain
            icon='el-icon-printer'
            :loading="loading3"
          >
            打印
          </el-button> -->
        </div>
        <!-- <div class='line'></div> -->
        <div class="middle" style="flex: 1;">
          <Cond @onCond="handleCond" function_code="redemption" @setCond="handleSetCond" />
        </div>
        <div class="right">
          <!-- <TableConf function_code="warehouse" @onRowList="handleRowList" /> -->
          <!-- <PrintConf function_code="redemptionPrint" @onRowList="handlePrintRow" /> -->
        </div>
      </div>
      <div class="total">
          <div class="total-right">
            <div class="total-right-item">订单总数 <span>{{ tableData.total || '0'}}</span></div>
            <div class="total-right-item">赎回总额 <span>￥{{ tableData.redemption_amount || '0.00'}}</span></div>
            <div class="total-right-item">赎回数量 <span>{{ tableData.redemption_goods_num || '0'}}</span></div>
            <div class="total-right-item">总净金重 <span>{{ tableData.redemption_gold_weight || '0' }}</span></div>
          </div>
        </div>
      <div class="table" style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden;" v-loading="loading">
        <el-table
          @row-dblclick="handleDalclick"
          :data="tableData.list"
          style="width: 100%"
          border
          height="670"
          @selection-change="handleSelectionChange"
          :row-class-name="tableRowClassName"
        >
          <!-- <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column> -->
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width">
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'goods' && item.field_alias !== 'member' && item.field_alias !== 'num' && item.field_alias !== 'recycle_price' && item.field_alias !== 'goldweight' && item.field_alias !== 'redemption_price'">
                  <span style="color: #F56C6C;" v-if="item.field_alias === 'refund_order_number'">
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                  <span v-else>
                    {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
                  </span>
                </template>
                <template v-if="item.field_alias === 'goods'">
                  <template v-for="(good, goodIndex) in scope.row.goods">
                    <div style="display: flex; padding: 10px;" :key="good.order_goods_id" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''">
                      <div>
                        <el-image :src="good.goods_pic"  :preview-src-list="[good.goods_pic]" style="width: 80px; height: 80px; object-fit: contain;" v-if="good.goods_pic" />
                        <img src="../../../../assets/images/nopic.png" style="width: 80px; height: 80px;" v-else />
                      </div>
                      <div style="margin-left: 15px; text-align: left;">
                        <div>品名：{{good.goods_name}}</div>
                        <div>品类：{{good.goods_type_name}}</div>
                        <div>货号：{{good.goods_number}}</div>
                        <!-- <div>
                          <el-tag v-if="scope.row.isRaw" type="warning">旧料</el-tag>
                        </div> -->
                        <div style="display: flex;">
                          <template v-for="item in good.goods_tag_pic">
                            <img :src="item" :key="item" style="margin-right: 5px;" />
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'recycle_price'">
                  <template v-for="(good, goodIndex) in scope.row.recycle_price">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'num'">
                  <template v-for="(good, goodIndex) in scope.row.num">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'goldweight'">
                  <template v-for="(good, goodIndex) in scope.row.goldweight">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'redemption_price'">
                  <template v-for="(good, goodIndex) in scope.row.redemption_price">
                    <div :key="goodIndex" :class="(goodIndex + 1) !== scope.row.goods.length? 'border': ''"
                    style="height: 107px; display: flex; justify-content: center; align-items: center;">
                      {{good}}
                    </div>
                  </template>
                </template>
                <template v-if="item.field_alias === 'member'">
                  <div>{{scope.row.member_name || '--'}}</div>
                  <div>{{scope.row.member_phone || '--'}}</div>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align='center'
            fixed='right'
            field_alias='operation'
            label='操作'
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <!-- <el-option label="打印" :value="2"></el-option> -->
                  <el-option label="解锁" :value="3" v-if="!noSockIds.includes(scope.row.redemption_order_id)"></el-option>
                  <el-option label="编辑" :value="4" v-if="noSockIds.includes(scope.row.redemption_order_id)"></el-option>
                  <el-option label="删除" :value="5" v-if="noSockIds.includes(scope.row.redemption_order_id)"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class='pagina'>
          <el-pagination
            background
            :current-page="page"
            layout='total, sizes, prev, pager, next, jumper'
            :total='tableData.total'
            :page-size='$store.state.pageSizes[0]'
            :page-sizes="$store.state.pageSizes"
            @current-change='handleCurrentChange'
            @size-change="handleSizeChange"
          ></el-pagination>
      </div>
    </template>
    <Detail v-if="detailVisible" @onHideDetail="handleHideDetail" :redemption_order_id="currRow.redemption_order_id" />
    <RedeemGoods v-if="redeemVisible" :dialogVisible="redeemVisible"  :redemption_order_id="currRow.redemption_order_id" @onHideRedeem="handleHideRedeem"  />
  </div>
</template>

<script>
import numeral from 'numeral';
import moment from 'moment';
import { getMyRedemptionListReq as getListReq, getRedemptionPrintReq, delRedemptionOrderReq } from '@/api/order/offlineOrder/list';
import Cond from '@/components/cond/Index.vue';
import Detail from './Detail.vue';
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import { getIsLock } from '@/api/monthSettle';
import RedeemGoods from "@/views/order/offlineOrder/salesOrder/RedeemGoods";

export default {
  data() {
    return {
      redeemVisible: false,
      noSockIds: [],
      printLoading: false,
      title: '赎回订单',
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      loading: false,
      detailVisible: false,
      currRow: {}, // 当前行
      page: 1,
      limit: this.$store.state.pageSizes[0],
      cond: {}, // 搜索的配置
      loading3: false,
      multipleSelection: [], // 多选列表
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '赎回单号', field_alias: 'redemption_order_number', t_width: 250 },
        { field_text: '回收订单号', field_alias: 'recycle_order_number', t_width: 250 },
        { field_text: '赎回货品', field_alias: 'goods', t_width: 300 },
        { field_text: '数量', field_alias: 'num', t_width: 120 },
        { field_text: '净金重', field_alias: 'goldweight', t_width: 120 },
        { field_text: '货品赎回价', field_alias: 'redemption_price', t_width: 120 },
        { field_text: '回收价', field_alias: 'recycle_price', t_width: 120 },
        { field_text: '会员', field_alias: 'member', t_width: 120 },
        { field_text: '主销售', field_alias: 'store_user_name', t_width: 120 },
        { field_text: '赎回金额', field_alias: 'paid_in_amount', t_width: 120 },
        { field_text: '赎回时间', field_alias: 'create_time', t_width: 200 },
        { field_text: '所属门店', field_alias: 'hierarchy_name', t_width: 120 },
      ],
      tableData: {
        list: [],
        total: 0,
      },
      activeArr: [],
    };
  },
  components: {
    Cond,
    Detail,
    PrintConf,
    LocalPrint,
    RedeemGoods,
  },
  created() {
    this.getList();
  },
  mounted() {
    if (this.$route.params.type) {
      if (this.$route.params.type === 'detail') {
        this.currRow.redemption_order_id = this.$route.params.key;
        this.detailVisible = true;
      }
    }
  },
  methods: {
    handleHideRedeem(getList) {
      this.currRow.select = "";
      this.redeemVisible = false;
      if (getList) {
        this.page = 1;
        this.getList();
      }
    },
    handleSetCond(cond) {
      this.cond = { ...cond };
    },
    handlePrint() {
      this.getRedemptionPrint({ ...this.cond });
    },
    getRedemptionPrint(cond) {
      this.printLoading = true;
      getRedemptionPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.printLoading = false;
            this.currRow.select = '';
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '打印时间', value: data.print_time, width: '24%' },
              { txt: '门店', value: data.hierarchy_name, width: '24%' },
            ];
            res.data.bottom = [];
            res.data.top.push(arr1);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
            };
            const decimal = {};
            const newArr = [];
            this.printData.list.forEach((item) => {
              item.goods.forEach((good, goodIndex) => {
                const newItem = { ...item, ...good };
                if (goodIndex === 0) {
                  newItem.rowspan = item.goods.length;
                }
                newArr.push(newItem);
              });
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  // 不需要合并的
                  if (!row.is_goods) {
                    if (!sum[row.field_alias]) {
                      sum[row.field_alias] = 0;
                    }
                    if (item[row.field_alias]) {
                      sum[row.field_alias] += Number(item[row.field_alias]);
                      if (!decimal[row.field_alias]) {
                        const arr = String(item[row.field_alias]).split('.');
                        if (arr.length === 2) {
                          decimal[row.field_alias] = arr[1].length;
                        } else {
                          decimal[row.field_alias] = 0;
                        }
                      }
                    }
                  } else {
                    // 需要合并的
                    if (!sum[row.field_alias]) {
                      sum[row.field_alias] = 0;
                    }
                    item.goods.forEach((good) => {
                      if (good[row.field_alias]) {
                        sum[row.field_alias] += Number(good[row.field_alias]);
                        if (!decimal[row.field_alias]) {
                          const arr = String(good[row.field_alias]).split('.');
                          if (arr.length === 2) {
                            decimal[row.field_alias] = arr[1].length;
                          } else {
                            decimal[row.field_alias] = 0;
                          }
                        }
                      }
                    });
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== 'index') {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list = newArr;
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.printLoading = false;
          this.currRow.select = '';
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handleDalclick(row) {
      this.currRow = row;
      // 详情
      this.detailVisible = true;
      setTimeout(() => {
        this.currRow.select = '';
      }, 100);
    },
    cellMouseLeave() {
      this.activeArr = [];
    },
    tableRowClassName({ rowIndex }) {
      const arr = this.activeArr;
      for (var i = 0; i < arr.length; i ++) {
        if (rowIndex === arr[i]) {
          return 'success-row';
        }
      }
    },
    cellMouseEnter(row) {
      this.activeArr = row.activeArr;
    },
    // 改变分页条数
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    getList() {
      this.loading = true;
      getListReq({ ...this.cond, page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            const { data } = res;
            data.order_amount = numeral(data.order_amount).format('0.00');
            data.order_discount = numeral(data.order_discount).format('0.00');
            data.paid_in_amount = numeral(data.paid_in_amount).format('0.00');
            // 处理数据
            // const newArr = [];
            // let rowIndex = 0;
            // data.refund_amount = numeral(data.refund_amount).format('0.00');
            // data.list.forEach((item, index) => {
            //   if (index > 0) {
            //     rowIndex += data.list[index - 1].goods.length;
            //   }
            //   item.index = index + 1;
            //   const activeArr = [];
            //   // item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss');
            //   item.goods.forEach((good, goodIndex) => {
            //     const realIndex = rowIndex + goodIndex;
            //     const obj = { ...good, ...item };
            //     if (goodIndex === 0) {
            //       obj.span = item.goods.length;
            //     }
            //     activeArr.push(realIndex);
            //     obj.activeArr = activeArr;
            //     newArr.push(obj);
            //   });
            // });
            // this.tableData = {
            //   ...res.data,
            //   list: newArr,
            // };
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
              item.recycle_price = [];
              item.num = [];
              item.goldweight = [];
              item.redemption_price = [];
              item.goods.forEach((good) => {
                item.recycle_price.push(good.recycle_price);
                item.num.push(good.num);
                item.goldweight.push(good.goldweight);
                item.redemption_price.push(good.redemption_price);
              });
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleHideDetail() {
      this.detailVisible = false;
    },
    handleHidePickUp() {
      this.pickUpVisible = false;
    },
    // 新增模块隐藏事件
    handleAddHide() {
      this.dialogVisible = false;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    /*
     *  导出
     */
    handleExport() {
      this.loading3 = true;
      getListReq({ ...this.cond, page: this.page, limit: this.limit, export: 2 })
        .then((res) => {
          this.loading3 = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const time = new Date().getTime();
          const a = document.createElement("a");
          a.setAttribute("download", `赎回订单列表${time}.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取订单详情
    // getOrderInfo(order_id) {
    //   getOrderInfoReq(order_id)
    //     .then((res) => {
    //       if (res.code === 1) {
    //         console.log(res.data);
    //       }
    //     });
    // },
    // 操作选择触发
    handleChange(e, row) {
      this.currRow = row;
      // 详情
      if (e === 1) {
        this.detailVisible = true;
        setTimeout(() => {
          this.currRow.select = '';
        }, 100);
      }
      // 解锁
      if (e === 3) {
        getIsLock({
          source: 'redemption',
          primary_key_id: row.redemption_order_id,
        })
          .then((res) => {
            if (res.code === 1) {
              // this.addVisiable = true;
              this.$message.success('解锁成功');
              row.select = '';
              this.currRow = {};
              this.noSockIds.push(row.redemption_order_id);
            }
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
      // 编辑
      if (e === 4) {
        this.redeemVisible = true;
      }
      // 删除
      if (e === 5) {
        this.$confirm('确定要删除该赎回单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            row.select = '';
            delRedemptionOrderReq({
              redemption_order_id: row.redemption_order_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('删除成功');
                  this.getList();
                }
              })

          })
          .catch(() => {
            row.select = '';
          })
      }
    },
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 5 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex === 9 || columnIndex === 10 || columnIndex === 11) {
        if (row.span) {
          return {
            rowspan: row.span,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        };
      }
    },
  },
};
</script>;

<style lang="less" scoped>
.border {
  border-bottom: 1px solid #ddd;
}
/deep/ .el-table__row .cell {
  padding: 0;
}

 /deep/.el-table .success-row {
  background: #f5f7fa;
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #E7541E;
      }
    }
  }
}
.label {
  text-align: left;
  color: white;
  background: #E7541E;
  width: 38px;
  line-height: 16px;
  padding: 2px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 5px;
}
</style>
